import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendRevenueApi, sendRevenueApi2 } from "../Data/Api";
import Post from "../Request/Post";
import { options } from "../Data/LoaderData";
import Message from "../Components/Message";

const Revenue = () => {
  //Hook to store date difference
  const [day, setDays] = useState(0);
  const [startDate, setStartDate] = useState("");
  const MyKidzHub = localStorage.getItem("MyKidzHub");

  //Hook to store data
  const [data, setData] = useState({});

  //to load on start
  useEffect(() => {
    let request = { day: day };
    let url = "";
    if(MyKidzHub){
      url = sendRevenueApi2;
    }else{
      url = sendRevenueApi;
    }
    hitOnBackend(url, request);
    // eslint-disable-next-line
  }, []);

  //Method to hit on backend
  const hitOnBackend = (url, request) => {
    if (MyKidzHub) {
      console.log(url)
      let promise = Post(url, request, "POST");
      promise.then((e) => {
        handleResponse(e);
      });
    }
    else {
      let promise = Post(url, request);
      console.log('url: ', url);
      promise.then((e) => {
        handleResponse(e);
      });
    }

  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  //Method to Handle search button
  const handleSearchButton = () => {
    const payload = {
      startDate: startDate,
    };
    let url = "";
    if(MyKidzHub){
      url = sendRevenueApi2;
    }else{
      url = sendRevenueApi;
    }
    hitOnBackend(url, payload);
    setDivState("block");
  };

  //Method to handle response
  const handleResponse = (e) => {
    console.log("e ", e);

    if (e === "Data Not Found") {
      toast.error("Network Error");
    } else {
      //ok
      setData({
        subRevenue: e.SubRevenue,
        renRevenue: e.RenRevenue,
        totalRevenue: e.totalRevenue,
        date: e.date,
      });
      setDivState("none");
    }
  };

  //Loader Stuff
  let Loader = require("react-loader");

  //Hook to store starting div state(loading div)
  const [divState, setDivState] = useState("block");

  //State for Loader
  // eslint-disable-next-line
  const [loaderState, setLoaderState] = useState(false);

  return (
    <>
      <div className="loading-div" style={{ display: `${divState}` }}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      {/* <!-- sec2 --> */}
      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="secondTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Revenue</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <span className="main-date-form">
            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Revenue</span>
              </p>
            </div>

            {/* <!-- date --> */}
            <div className="date-inner">
              <div className="end-date">
                <label htmlFor="start">Choose Date:</label>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  onChange={handleStartDateChange}
                />
              </div>
              <div className="date-search-btn">
                <button
                  className="bg-orange"
                  type="submit"
                  onClick={() => {
                    handleSearchButton();
                  }}
                >
                  Search
                </button>
              </div>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box">
              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                    <tr>
                      <th>S.No</th>
                      <th>Date</th>
                      <th>Subscritpion Revenue</th>
                      <th>Renewals Revenue</th>
                      <th>Total Revenue</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>{data.date}</td>
                      <td>{data.subRevenue}</td>
                      <td>{data.renRevenue}</td>
                      <td>{data.totalRevenue}</td>
                    </tr>
                  </tbody>
                </table>

                {!data && <Message message="No Data Found!" />}
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};
export default Revenue;
