import React, { useState } from "react";
import { callbackLogsApi } from "../Data/Api";
import Post from "../Request/Post";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Message from "../Components/Message";
import { options } from "../Data/LoaderData";
import { Paginator } from "primereact/paginator";

const CallbackLogs = () => {
  //Hook to store starting div state(loading div)
  const [divState, setDivState] = useState("block");

  //State for Loader
  // eslint-disable-next-line
  const [loaderState, setLoaderState] = useState(false);

  //Hook to store data
  const [data, setData] = useState([]);

  const [msisdn, setMsisdn] = useState("");

  const handleMsisdnChange = (e) => {
    setMsisdn(e.target.value);
  };

  //Method to hit on backend
  const hitOnBackend = (url, request) => {
    setDivState("block");
    setLoaderState(true);
    let promise = Post(url, request);
    promise.then((e) => {
      setDivState("none");
      setLoaderState(false);
      handleResponse(e);
    });
  };

  //Method to handle response
  const handleResponse = (e) => {
    if (e === "Data Not Found") {
      toast.error("Data Not Found");
    } else {
      console.log("e ", e);
      setData(e);
    }
  };

  //Loader Stuff
  let Loader = require("react-loader");

  const handleSearch = () => {
    hitOnBackend(callbackLogsApi, { msisdn: msisdn });
  };

  const [tableFirst, settableFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);

  const onPageChangeTable = (event) => {
    const page = event?.page + 1;
    settableFirst(10 * (page - 1));
  };

  return (
    <>
      <div className="loading-div" style={{ display: `${divState}` }}>
        <Loader loaded={!loaderState} options={options} className="spinner" />
      </div>

      {/* <!-- sec2 --> */}
      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="secondTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Callback Logs</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <span className="main-date-form">
            <div className="date-inner date-1-sec">
              <div className="end-date">
                <label htmlFor="from">Enter MSISDN: </label>
                <input
                  type="number"
                  id="msidn"
                  name="msisdn"
                  value={msisdn}
                  onChange={handleMsisdnChange}
                />
              </div>

              <div className="date-search-btn">
                <button
                  type="submit"
                  onClick={() => {
                    handleSearch();
                  }}
                >
                  Search
                </button>
              </div>
            </div>

            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Callback Logs</span>
              </p>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box">
              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Date</th>
                      <th>MSISDN</th>
                      <th>Callback</th>
                      <th>Type</th>
                    </tr>
                    {data.length <= 0 ? (
                      <tr>
                        <td colSpan="5">
                          <Message message="No Data Found!" />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data
                          ?.slice(tableFirst, tableFirst + tableRows)
                          ?.map((dataItem, index) => (
                            <tr key={index}>
                              <td>{tableFirst + index + 1}</td>
                              <td>{dataItem?.datetime}</td>
                              <td>{dataItem?.msisdn}</td>
                              <td
                                style={{
                                  overflowX: "scroll",
                                  maxWidth: "300px",
                                }}
                              >
                                {dataItem?.callback}
                              </td>
                              <td>{dataItem?.type}</td>
                            </tr>
                          ))}
                      </>
                    )}
                  </tbody>
                </table>

                <Paginator
                  first={tableFirst}
                  rows={tableRows}
                  totalRecords={data.length}
                  onPageChange={onPageChangeTable}
                />
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export default CallbackLogs;
