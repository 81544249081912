import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Pages/Login";
import Revenue from "../Pages/Revenue";

import Logs from "../Pages/Logs";
import SubUnsub from "../Pages/SubUnsub";
import AirtimeLogs from "../Pages/AirtimeLogs";
import ParticularLogs from "../Pages/ParticularLogs";
import Promotiondata from "../Pages/Promotiondata";
import CallbackLogs from "../Pages/CallbackLogs";
import Unsubscription from "../Pages/Unsubscription";

const Routing = () => {
  return (
    <>
      {/* <BrowserRouter> */}
      <Routes>
        <Route element={<Login />} path="/" exact={true}></Route>
        <Route element={<Logs />} path="/logs" exact={true}></Route>
        <Route element={<Revenue />} path="/revenue" exact={true}></Route>
        <Route
          element={<Promotiondata />}
          path="/promotion"
          exact={true}
        ></Route>
        <Route element={<SubUnsub />} path="/subUnsubData" exact={true}></Route>
        <Route
          element={<ParticularLogs />}
          path="/particular"
          exact={true}
        ></Route>
        <Route element={<CallbackLogs />} path="/callback-logs" exact={true} />

        <Route element={<Unsubscription />} path="/unsubscription" exact={true} />
        {/* <Route element={<AirtimeLogs/>} path="/airtimeLogs" exact={true}/> */}
      </Routes>
      {/* </BrowserRouter> */}
    </>
  );
};
export default Routing;
