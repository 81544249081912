import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import logo from "../Images/avatar.png";
import { loginApi } from "../Data/Api";
import Post from "../Request/Post";
import { options } from "../Data/LoaderData";
import { projectToken } from "../Data/Api";
import classes from "./Login.module.css";

const Login = () => {
  const navigate = useNavigate();

  const [MyKidzHub, setMyKidzHub] = useState(false);
  const clearTokenAfterTimeout = () => {
    setTimeout(() => {
      localStorage.removeItem("token");
      toast.info("Session expired. Please log in again.");
      navigate("/");
    }, 3600000); // 1 hour in milliseconds
  };

  // To handle login button
  const handleLoginButton = () => {
    setDivState("block");
    checkingBlankValues();
  };

  // Method to check blank or null values
  const checkingBlankValues = () => {
    let usernameResult = true;
    let passwordResult = true;

    if (!credentials.username || credentials.username.trim() === "") {
      toast.error("Enter Username First !!");
      usernameResult = false;
    }
    if (!credentials.password || credentials.password.trim() === "") {
      toast.error("Enter Password First !!");
      passwordResult = false;
    }

    if (usernameResult && passwordResult) {
      console.log('usernameResult: ',  credentials.username);
      console.log("object")
      if(credentials.username === "MyKidzHub"){
        console.log("jkdbccyudvs")
        setMyKidzHub(true);
        localStorage.setItem("MyKidzHub", true);
      }
      matchCredentials();
    } else {
      setDivState("none");
    }
  };

  // Method to match credentials from backend
  const matchCredentials = () => {
    let promise = Post(loginApi, credentials);
    promise
      .then((e) => {
        handleApiResponse(e);
      })
      .catch((error) => {
        console.error("Error during login: ", error);
        toast.error("Error during login. Please try again.");
        setDivState("none");
      });
  };

  // Method to handle Api Response
  const handleApiResponse = (e) => {
    console.log("API response: ", e);
    setDivState("none");

    if (e === 0) {
      toast.error("Something Went Wrong");
    } else if (e === 1) {
      localStorage.setItem("token", projectToken);
      clearTokenAfterTimeout();
      navigate("/logs");
    } else {
      toast.error("Unexpected response from server");
    }
  };

  // Hook to store user credentials
  const [credentials, setCredentials] = useState({});

  console.log(credentials, "c");

  // Loader Stuff
  let Loader = require("react-loader");

  // Hook to store starting div state (loading div)
  const [divState, setDivState] = useState("none");

  // State for Loader
  // eslint-disable-next-line
  const [loaderState, setLoaderState] = useState(false);

  return (
    <>
      <div className="loading-div" style={{ display: `${divState}` }}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      <ToastContainer />
      <section className="login-sec">
        <div className="login-heading">
          <h2>Live Revenue Dashboard</h2>
        </div>
        <div className="login-box">
          <div className="login-inner-logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="login-form">
            <div className="form">
              <div className="succe-massge">
                <p>Enter Credentials</p>
              </div>
              <div className="user">
                {/* <span className="icon-u">
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>
                <span>
                  <select
                    className={classes.selector}
                    placeholder="Select the Service"
                  >
                    <option>BigCash</option>
                    <option>MyKidsHub</option>
                  </select> */}
                  <input
                    type="text"
                    placeholder="Username"
                    name="uname"
                    required
                    onChange={(e) => {
                      setCredentials({ ...credentials, username: e.target.value });
                    }}
                  />
                {/* </span> */}
              </div>
              <div className="password">
                <span className="icon-l">
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
                <span>
                  <input
                    type="password"
                    placeholder="Password"
                    name="psw"
                    required
                    onChange={(e) => {
                      setCredentials({
                        ...credentials,
                        password: e.target.value,
                      });
                    }}
                  />
                </span>
              </div>
              <div
                className="submit-btn"
                onClick={() => {
                  handleLoginButton();
                }}
              >
                <button type="submit">
                  <span>
                    <i className="fa fa-sign-in" aria-hidden="true"></i>
                  </span>{" "}
                  <span>Login</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
