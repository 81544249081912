import React from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {

  //Use to send on other route/page
  const navigate=useNavigate();

  //Method to handle navigation of sidebar
  const handleNavigate=(route)=>{
    navigate(route);
  }

  const MyKidzHub = localStorage.getItem("MyKidzHub");
  const MtnZambia = localStorage.getItem("MyKidzHub");
  console.log('MyKidzHub: ', MyKidzHub);

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-inner">
          <p>MAIN</p>
        </div>
        {/* <!-- 1 --> */}
        <button
          className="tablinks"
          onClick={()=>{
            handleNavigate("/logs");
          }}
          id="defaultOpen"
        >
          {" "}
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Billing Logs</span>
        </button>
        {/* <!-- 2 --> */}
        <button className="tablinks" onClick={()=>{
          handleNavigate("/revenue");
        }}>
          {" "}
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Revenue</span>
        </button>
        <button className="tablinks" onClick={()=>{
          handleNavigate("/subUnsubData");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span> Sub & Churn</span>
        </button>

        <button className="tablinks" onClick={()=>{
          handleNavigate("/unsubscription");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Unsubscribe</span>
        </button>



        {!MyKidzHub &&  <button className="tablinks" onClick={()=>{
          handleNavigate("/promotion");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span> PromotionCallback</span>
        </button>}


        <button className="tablinks" onClick={()=>{
          handleNavigate("/particular");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>User Logs</span>
        </button>

        {!MyKidzHub && <button
          className="tablinks"
          onClick={()=>{
            handleNavigate("/callback-logs");
          }}
          id="defaultOpen"
        >
          {" "}
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Callback Logs</span>
        </button>}

        {/* <button className="tablinks" onClick={()=>{
          handleNavigate("/airtimeLogs");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Airtime Logs</span>
        </button> */}
        
      </div>
    </>
  );
};
export default Sidebar;