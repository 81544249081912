  //const pre=`http://localhost:8348/`;
 // const pre=`http://176.9.90.155:8348/`
const pre = `https://hlsvideos.gameninja.in/`;
// const pre=`http://88.99.5.236:8989/`;
// const pre = `https://www.backendreport.econetbigcash.com/`;

const myKidsHubBaseURL = `https://hlsvideos.gameninja.in/api/v1/`;

const loginApi = `${pre}login`;
export { loginApi };

const loginApi2 = `${myKidsHubBaseURL}login`;
export { loginApi2 };

const sendLogsApi = `${pre}sendLogs`;
export { sendLogsApi };

const sendLogsApi2 = `${myKidsHubBaseURL}sendLogs`;
export { sendLogsApi2 };

const sendRevenueApi = `${pre}sendRevenue`;
export { sendRevenueApi };

const sendRevenueApi2 = `${myKidsHubBaseURL}sendRevenue`;
export { sendRevenueApi2 };

const sendSubAndUnsubDataApi = `${pre}sendSubAndUnsubData`;
export { sendSubAndUnsubDataApi };

const sendSubAndUnsubDataApi2 = `${myKidsHubBaseURL}sendSubAndUnsubData`;
export { sendSubAndUnsubDataApi2 };

const sendPromotionDataApi = `${pre}sendPromotionData`;
export { sendPromotionDataApi };

const sendPromotionDataApi2 = `${myKidsHubBaseURL}sendPromotionData`;
export { sendPromotionDataApi2 };

const sendParticularDataApi = `${pre}sendParticularDataApi`;
export { sendParticularDataApi };

const sendParticularDataApi2 = `${myKidsHubBaseURL}sendParticularDataApi`;
export { sendParticularDataApi2 };

const sendWinnersApi = `${pre}sendWinners`;
export { sendWinnersApi };

const sendWinnersApi2 = `${myKidsHubBaseURL}sendWinners`;
export { sendWinnersApi2 };

const callbackLogsApi = `${pre}sendCallBackLogs`;
export { callbackLogsApi };

const callbackLogsApi2 = `${myKidsHubBaseURL}sendCallBackLogs`;
export { callbackLogsApi2 };

const unsubApi = `${pre}unsub`;
export {unsubApi};

const unsubApiMykizhub2 = `${myKidsHubBaseURL}unsub`;
export { unsubApiMykizhub2 };

const projectToken = `KJK@#$#JKAREZJK#$#JK`;
export { projectToken };
