import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendSubAndUnsubDataApi, sendSubAndUnsubDataApi2 } from "../Data/Api";
import Post from "../Request/Post";

import { options } from "../Data/LoaderData";
import moment from "moment/moment";
import Message from "../Components/Message";

const SubUnsub = () => {
  const [day, setDays] = useState(0);

  //Hook to store starting div state(loading div)
  const [divState, setDivState] = useState("block");
  const MyKidzHub = localStorage.getItem("MyKidzHub");

  //State for Loader
  // eslint-disable-next-line
  const [loaderState, setLoaderState] = useState(false);

  //Hook to store data
  const [data, setData] = useState({});
  const [startDate, setStartDate] = useState("");

  //to load on start
  useEffect(() => {
    let request = { day: day };
    let url = "";
    if(MyKidzHub){
      url = sendSubAndUnsubDataApi2;
    }else{
      url = sendSubAndUnsubDataApi;
    }
    hitOnBackend(url, request);
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  //Method to hit on backend
  const hitOnBackend = (url, request) => {
    setDivState("block");
    setLoaderState(true);
    let promise = Post(url, request);
    promise.then((e) => {
      setDivState("none");
      setLoaderState(false);
      handleResponse(e);
    });
  };

  //Method to handle response
  const handleResponse = (e) => {
    if (e === "Data Not Found") {
      toast.error("Data Not Found");
    } else {
      console.log("e ", e);
      setData({
        date: e.date,
        totalSubCount: e.totalSubCount,
        subCount: e.subCount,
        totalUnsubCount: e.totalUnsubCount,
        unsubCount: e.unsubCount,
        dailySubCount: e.dailySubCount,
        weeklySubCount: e.weeklySubCount,
        monthlySubCount: e.monthlySubCount,
        dailyRenCount: e.dailyRenCount,
        weeklyRenCount: e.weeklyRenCount,
        monthlyRenCount: e.monthlyRenCount,
        totalRenCount: e.totalRenCount,
      });
      //console.log("Data",data);
    }
  };

  //Loader Stuff
  let Loader = require("react-loader");

  const handleSearch = () => {
    let url = "";
    if(MyKidzHub){
      url = sendSubAndUnsubDataApi2;
    }else{
      url = sendSubAndUnsubDataApi;
    }
    hitOnBackend(url, { startDate: startDate });
  };

  return (
    <>
      <div className="loading-div" style={{ display: `${divState}` }}>
        <Loader loaded={!loaderState} options={options} className="spinner" />
      </div>

      {/* <!-- sec2 --> */}
      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="secondTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Sub & Churn</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <span className="main-date-form">
            <div className="date-inner date-1-sec">
              <div className="end-date">
                <label htmlFor="from">Select Date:</label>
                <input
                  type="date"
                  id="from"
                  name="trip-start"
                  onChange={handleStartDateChange}
                />
              </div>

              <div className="date-search-btn">
                <button
                  type="submit"
                  onClick={() => {
                    handleSearch();
                  }}
                >
                  Search
                </button>
              </div>
            </div>

            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Sub & Churn</span>
              </p>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box">
              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                    <tr>
                      <th>date</th>
                      <th>Total Sub</th>
                      <th>New Sub</th>
                      {/* <th>New Charged Sub</th> */}
                      <th>Total Churn</th>
                      <th>Churn</th>
                    </tr>
                    <tr>
                      <td>{data.date}</td>
                      <td>{data.totalSubCount}</td>
                      <td>{data.subCount}</td>
                      {/* <td>{data.newChargedSub}</td> */}
                      <td>{data.totalUnsubCount}</td>
                      <td>{data.unsubCount}</td>
                    </tr>
                  </tbody>
                </table>

                {!data && <Message message="No Data Found!" />}
              </div>
            </div>
          </span>
        </div>

        {/* For All - Data */}

        {/* <!-- date --> */}
        <div className="date-form" style={{ marginTop: "70px" }}>
          <span className="main-date-form">
            <div className="date-inner date-1-sec" style={{ display: "none" }}>
              <div className="end-date">
                <label htmlFor="from">Select Date:</label>
                <input
                  type="date"
                  id="from"
                  name="trip-start"
                  onChange={handleStartDateChange}
                />
              </div>

              <div className="date-search-btn" style={{ display: "none" }}>
                <button
                  type="submit"
                  onClick={() => {
                    handleSearch();
                  }}
                >
                  Search
                </button>
              </div>
            </div>

            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>All Report</span>
              </p>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box">
              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                    <tr>
                      <th>Date</th>
                      <th>Total Sub</th>
                      <th>New Subs</th>
                      {/* <th>New Subs Charged</th> */}
                      <th>New Sub Daily</th>
                      <th>New Sub Weekly</th>
                      <th>New Sub Monthly</th>
                      <th>Daily Package Renewals</th>
                      <th>Weekly Package Renewals</th>
                      <th>Monthly Package Renewals</th>
                      <th>Total Renewals</th>
                    </tr>
                    <tr>
                      <td>{data.date}</td>
                      <td>{data.totalSubCount}</td>
                      <td>{data.subCount}</td>
                      {/* <td>{data.newChargedSub}</td> */}
                      <td>{data.dailySubCount}</td>
                      <td>{data.weeklySubCount}</td>
                      <td>{data.monthlySubCount}</td>
                      <td>{data.dailyRenCount}</td>
                      <td>{data.weeklyRenCount}</td>
                      <td>{data.monthlyRenCount}</td>
                      <td>{data.totalRenCount}</td>
                      {/* <td>{data.totalUnsub}</td>
          <td>{data.todayUnsub}</td> */}
                    </tr>
                  </tbody>
                </table>

                {!data && <Message message="No Data Found!" />}
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};
export default SubUnsub;
