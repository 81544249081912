import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendLogsApi, sendLogsApi2 } from "../Data/Api";
import Post from "../Request/Post";
import { options } from "../Data/LoaderData";
import Loader from "react-loader";
import Message from "../Components/Message";

const Logs = () => {
  const [data, setData] = useState([]); // Ensure data is initialized as an array
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("Sub"); // Default value to match your options
  const [divState, setDivState] = useState("block");
  const [loaderState, setLoaderState] = useState(false);
  const MyKidzHub = localStorage.getItem("MyKidzHub");
  const [message, setMessage] = useState("");
  console.log('MyKidzHub: ', MyKidzHub);

  useEffect(() => {
    hittingBackendApi();
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    hittingBackendApi();
  };

  const hittingBackendApi = () => {
    const payload = {
      startDate: startDate,
      endDate: endDate,
      type: type,
    };

    console.log("Payload:", payload); // Debugging line
    if (MyKidzHub) {
      setDivState("block");
      setLoaderState(true); // Show loader
      Post(sendLogsApi2, payload, "POST") // Ensure method is POST
        .then((response) => {
          console.log("Response:", response);
          setLoaderState(false); // Hide loader
          setDivState("none");
          handleResponse(response);
        })
        .catch((error) => {
          setLoaderState(false); // Hide loader
          console.error("Error fetching logs:", error);
          toast.error("An error occurred while fetching logs.");
          setData([]); // Ensure data is always an array
        });
    }
    else {
      setDivState("block");
      setLoaderState(true); // Show loader

      Post(sendLogsApi, payload, "POST") // Ensure method is POST
        .then((response) => {
          setLoaderState(false); // Hide loader
          setDivState("none");
          handleResponse(response);
        })
        .catch((error) => {
          setLoaderState(false); // Hide loader
          console.error("Error fetching logs:", error);
          toast.error("An error occurred while fetching logs.");
          setData([]); // Ensure data is always an array
        });
    }
  };

  const handleResponse = (response) => {
    console.log("API Response:", response); // Debugging line

    if (response === "Data Not Found" || !Array.isArray(response)) {
      toast.error("Data Not Found");
      setMessage("Data Not Found");
      setData([]); // Ensure data is an array
    } else {
      setData(response); // Ensure data is an array
    }
    setDivState("none");
  };

  const handlePagination = (direction) => {
    if (direction === "previous") {
      if (currentPage > 0) {
        setCurrentPage(currentPage - 1);
      }
    } else if (direction === "next") {
      if ((currentPage + 1) * itemsPerPage < data.length) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const paginatedData = data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <>
      <div className="loading-div" style={{ display: `${divState}` }}>
        <Loader loaded={!loaderState} options={options} className="spinner" />
      </div>

      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="firstTab" className="tabcontent">
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Billing Logs</span>
          </div>
        </div>

        <div className="date-form">
          <span className="main-date-form ss">
            <form className="date-inner date-1-sec" onSubmit={handleSearch}>
              <div className="end-date">
                <label htmlFor="start">Start Date:</label>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  value={startDate}
                  onChange={handleStartDateChange}
                  required
                />
              </div>

              <div className="end-date">
                <label htmlFor="end">End Date:</label>
                <input
                  type="date"
                  id="end"
                  name="trip-end"
                  value={endDate}
                  onChange={handleEndDateChange}
                  required
                />
              </div>

              <div className="end-date">
                <label htmlFor="type">Choose Log Type:</label>
                <select
                  id="type"
                  name="type"
                  value={type}
                  required
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="Sub">Subscription Billing</option>
                  <option value="Ren">Renewals Billing</option>
                </select>
              </div>
              <div className="date-search-btn">
                <button type="submit">Search</button>
              </div>
            </form>

            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Logs</span>
              </p>
            </div>

            <div className="main-box">
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                    <tr>
                      <th>S.No</th>
                      <th>Msisdn</th>
                      <th>Amount</th>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Mode</th>
                      <th>Pack</th>
                      <th>Service</th>
                    </tr>
                    {paginatedData.length > 0 &&
                      paginatedData.map((value, index) => (
                        <tr key={value.id}>
                          {!MyKidzHub ? (
                            <>
                              <td>{currentPage * itemsPerPage + index + 1}</td>
                              <td>{value.ani}</td>
                              <td>{value.amount}</td>
                              <td>{value.date_time}</td>
                              <td>{value.type}</td>
                              <td>{value.m_act}</td>
                              <td>{value.pack}</td>
                              <td>{value.service}</td>
                            </>
                          ) : (
                            <>
                              <td>{currentPage * itemsPerPage + index + 1}</td>
                              <td>{value.ani}</td>
                              <td>{value.deducted_amount}</td>
                              <td>{new Date(value.datetime).toISOString().split('T')[0]}</td>
                              <td>{value.type_event}</td>
                              <td>{value.mode}</td>
                              <td>{value.pack_type}</td>
                              <td>MyKidzHub</td>
                            </>)}
                        </tr>
                      ))}


                  </tbody>
                </table>
                  {data.length === 0 && (
                    <Message message="No Data Found!" />
                  )}
              </div>

              <div className="pagination-sec">
                <div className="pagination-next">
                  <ul className="pagination">
                    <li className="page-item">
                      <span
                        className="page-link"
                        onClick={() => handlePagination("previous")}
                        disabled={currentPage === 0}
                      >
                        Previous
                      </span>
                    </li>
                    <li className="page-item">
                      <span
                        className="page-link"
                        onClick={() => handlePagination("next")}
                        disabled={
                          (currentPage + 1) * itemsPerPage >= data.length
                        }
                      >
                        Next
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export default Logs;
