import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendParticularDataApi, sendParticularDataApi2 } from "../Data/Api";
import Post from "../Request/Post";
import { options } from "../Data/LoaderData";
import { Paginator } from "primereact/paginator";
import Message from "../Components/Message";

const ParticularLogs = () => {
  const [startDate, setStartDate] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const [unsubscriptionLogs, setUnsubscriptionLogs] = useState([]);
  const [billingSuccessLogs, setBillingSuccessLogs] = useState([]);
  const [subscriptionLogs, setSubscriptionLogs] = useState([]);
  const [divState, setDivState] = useState("block");
  const [message, setMessage] = useState(true);
  const [message2, setMessage2] = useState(true);
  const [message3, setMessage3] = useState(true);
  const [loaderState, setLoaderState] = useState(false);
  const [active, setActive] = useState(false);
  const MyKidzHub = localStorage.getItem("MyKidzHub");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [table1First, settable1First] = useState(0);
  const [table1Rows, setTable1Rows] = useState(10);

  const [table2First, settable2First] = useState(0);
  const [table2Rows, setTable2Rows] = useState(10);

  const [table3First, settable3First] = useState(0);
  const [table3Rows, setTable3Rows] = useState(10);

  const onPageChangeTable1 = (event) => {
    const page = event?.page + 1;
    settable1First(10 * (page - 1));
  };

  const onPageChangeTable2 = (event) => {
    const page = event?.page + 1;
    settable2First(10 * (page - 1));
  };

  const onPageChangeTable3 = (event) => {
    const page = event?.page + 1;
    settable3First(10 * (page - 1));
  };

  const hitOnBackend = (url, request) => {
    setLoaderState(true); // Show loader before making the request
    Post(url, request)
      .then((e) => {
        console.log("API Response: ", e);
        handleResponse(e);
      })
      .catch(() => {
        setLoaderState(false); // Hide loader in case of an error
        toast.error("Network Error");
      });
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleMsisdnChange = (e) => {
    setMsisdn(e.target.value);
  };

  const handleSearchButton = (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reset to first page on new search
    const payload = {
      startDate: startDate,
      msisdn: msisdn,
      page: 0, // First page
      size: itemsPerPage,
    };
    let url = "";
    if (MyKidzHub) {
      url = sendParticularDataApi2;
    } else {
      url = sendParticularDataApi;
    }
    hitOnBackend(url, payload);
  };

  const handleResponse = (e) => {
    console.log(e, "e");
    if (e === "Data Not Found") {
      toast.error("No Data Found");
    }
    else {
      setUnsubscriptionLogs(e.UnsubscriptionLogs || []);

      setBillingSuccessLogs(e.BillingSuccessLogs || []);
      setSubscriptionLogs(e.SubscriptionLogs || []);
      setTotalItems(e?.subscriptionLogs?.totalElements || 0);
    }
    if (billingSuccessLogs.length === 0) {
      setMessage(false)
    }
    if (subscriptionLogs.length == 0) {
      setMessage2(false)
    }
    setLoaderState(false);
  };

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    const payload = {
      startDate: startDate,
      msisdn: msisdn,
      page: pageNumber - 1, // Adjusting for zero-based index
      size: itemsPerPage,
    };
    hitOnBackend(sendParticularDataApi, payload);
  };

  let Loader = require("react-loader");

  console.log(unsubscriptionLogs, "unsll");

  // const dateCheck = (date) => {
  //   const updatedDate = date.split("T")[0];
  //   const currentDAte = new Date(date);
  //   if (currentDAte === updatedDate) {
  //     setActive(true);
  //     return date
  //   }
  //   return date;

  // }

  const dateCheck = (date) => {
    const currentDate = new Date();
    const logDate = new Date(date);
    // Check if the log date is the same as the current date
    return logDate.toDateString() === currentDate.toDateString();
  };

  return (
    <>
      <div
        className="loading-div"
        style={{ display: loaderState ? "block" : "none" }}
      >
        <Loader loaded={!loaderState} options={options} className="spinner" />
      </div>

      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="secondTab" className="tabcontent">
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">User Logs</span>
          </div>
        </div>

        <div className="date-form">
          <span className="main-date-form">
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Logs</span>
              </p>
            </div>

            <form onSubmit={handleSearchButton} className="date-inner">
              {/* <div className="end-date">
                <label htmlFor="start">Choose Date:</label>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  onChange={handleStartDateChange}
                  required
                  value={startDate}
                />
              </div> */}
              <div className="msisdn-input">
                <label htmlFor="msisdn">Enter MSISDN:</label>
                <input
                  type="text"
                  id="msisdn"
                  name="msisdn"
                  onChange={handleMsisdnChange}
                />
              </div>
              <div className="date-search-btn">
                <button
                  className="bg-orange"
                  type="submit"
                // onClick={handleSearchButton}
                >
                  Search
                </button>
              </div>
            </form>

            <div className="main-box">
              <div className="table-sec">
                <h3>Subscription Logs</h3>
                <table className="main-table">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Msisdn</th>
                      <th>Amount</th>
                      <th>Mode</th>
                      <th>Subscription Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptionLogs
                      .slice(table2First, table2First + table2Rows)
                      .map((log, index) => (
                        <tr key={index}>
                          <td>{table2First + index + 1}</td>
                          <td>{log?.ani}</td>
                          <td>{log?.amount}</td>
                          <td>{log?.m_act}</td>
                          {/* <td>{dateCheck(log?.sub_date_time)}</td>
                          <td>{active ? "ACTIVE" : null}</td> */}
                          <td>{dateCheck(log?.sub_date_time) ? log?.sub_date_time : log?.sub_date_time}</td>
                          <td>{dateCheck(log?.sub_date_time) ? "Active" : "Not-Active"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {message2 && subscriptionLogs.length == 0 && (
                  <Message message="No Data Found!" />
                )}

                <Paginator
                  first={table2First}
                  rows={table2Rows}
                  totalRecords={subscriptionLogs.length}
                  onPageChange={onPageChangeTable2}
                />

                <h3>Billing Success Logs</h3>
                <table className="main-table">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Msisdn</th>
                      <th>Amount</th>
                      <th>Mode</th>
                      <th>Pack</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billingSuccessLogs
                      .slice(table1First, table1First + table1Rows)
                      .map((log, index) => (
                        <tr key={index}>
                          <td>{table1First + index + 1}</td>
                          <td>{log?.ani}</td>
                          <td>{log?.amount}</td>
                          <td>{log?.m_act}</td>
                          <td>{log?.pack}</td>
                          {/* <td>{log?.last_billed_date}</td> */}
                          <td>{dateCheck(log?.last_billed_date) ? log?.last_billed_date : log?.last_billed_date}</td>
                          <td>{dateCheck(log?.last_billed_date) ? "Active" : "Not-Active"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {message3 && billingSuccessLogs.length == 0 && (
                  <Message message="No Data Found!" />
                )}

                <Paginator
                  first={table1First}
                  rows={table1Rows}
                  totalRecords={billingSuccessLogs.length}
                  onPageChange={onPageChangeTable1}
                />

                <h3>Unsubscription Logs</h3>
                <table className="main-table">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Msisdn</th>
                      <th>M_Deact</th>
                      <th>M_Act</th>
                      <th>Unsubscription Date</th>
                      <th>Subscription Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unsubscriptionLogs
                      .slice(table3First, table3First + table3Rows)
                      ?.map((log, index) => (
                        <tr key={index}>
                          <td>{table3First + index + 1}</td>
                          <td>{log?.ani}</td>
                          <td>{log?.m_deact}</td>
                          <td>{log?.m_act}</td>
                          <td>{log?.unsub_date_time}</td>
                          {/* <td>{log?.sub_date_time}</td> */}
                          <td>{dateCheck(log?.sub_date_time) ? log?.sub_date_time : log?.sub_date_time}</td>
                          <td>{dateCheck(log?.sub_date_time) ? "Active" : "Not-Active"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {message && billingSuccessLogs.length === 0 && (
                  <Message message="No Data Found!" />
                )}

                <Paginator
                  first={table3First}
                  rows={table3Rows}
                  totalRecords={unsubscriptionLogs.length}
                  onPageChange={onPageChangeTable3}
                />
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export default ParticularLogs;
