import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { options } from "../Data/LoaderData";
import { unsubApi, unsubApiMykizhub2 } from "../Data/Api"; 

const Unsubscription = () => {
  const MyKidzHub = localStorage.getItem("MyKidzHub");
 
  const [msisdn, setMsisdn] = useState("");
  const [loaderState, setLoaderState] = useState(false);

  const handleMsisdnChange = (e) => {
    setMsisdn(e.target.value);
  };

  const handleSearchButton = (e) => {
    e.preventDefault();
    if (!msisdn) {
      toast.error("Please enter a valid MSISDN");
      return;
    }

    setLoaderState(true);

    // Choose the API based on the presence of MyKidzHub in localStorage
    const apiUrl = MyKidzHub ? unsubApiMykizhub2 : unsubApi;
    const url = `${apiUrl}/${msisdn}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Handle plain text response
      })
      .then((data) => {
        console.log("API Response:", data); // Log the API response
        handleResponse(data.trim()); // Use trim to handle any extra whitespace
        setLoaderState(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        setLoaderState(false);
        toast.error("Network Error");
      });
  };

  const handleResponse = (data) => {
    if (data === "Success") {
      toast.success("Unsubscription Successful.");
    } else if (data === "Failed") {
      toast.error("Unsubscription failed.");
    } else {
      toast.error("Unexpected response.");
    }
  };

  let Loader = require("react-loader");

  return (
    <>
      <div
        className="loading-div"
        style={{ display: loaderState ? "block" : "none" }}
      >
        <Loader loaded={!loaderState} options={options} className="spinner" />
      </div>

      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="secondTab" className="tabcontent">
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Unsubscription</span>
          </div>
        </div>

        <div className="date-form">
          <span className="main-date-form">
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Unsubscription</span>
              </p>
            </div>

            <form onSubmit={handleSearchButton} className="date-inner">
              <div className="msisdn-input">
                <label htmlFor="msisdn">Enter MSISDN:</label>
                <input
                  type="text"
                  id="msisdn"
                  name="msisdn"
                  value={msisdn}
                  onChange={handleMsisdnChange}
                  required
                />
              </div>
              <div className="date-search-btn">
                <button className="bg-orange" type="submit">
                  Unsubscribe
                </button>
              </div>
            </form>
          </span>
        </div>
      </div>
    </>
  );
};

export default Unsubscription;
