import React from 'react';
import classes from "./Message.module.css";

const Message = ({message}) => {
  return (
    <p className={classes.message}>
        {message}
    </p>
  )
}

export default Message