import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendPromotionDataApi } from "../Data/Api";
import Post from "../Request/Post";
import { options } from "../Data/LoaderData";
import 'react-toastify/dist/ReactToastify.css';
import Message from "../Components/Message";

const Promotiondata = () => {
  const [day, setDays] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [data, setData] = useState([]);
  const [divState, setDivState] = useState('block');
  const [loaderState, setLoaderState] = useState(false);
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    let request = { day: day };
    hitOnBackend(sendPromotionDataApi, request);
  }, []);

  const hitOnBackend = (url, request) => {
    let promise = Post(url, request);
    promise.then(e => {
      handleResponse(e);
    }).catch(error => {
      toast.error("Network Error");
      setDivState('none');
    });
  }

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSearchButton = () => {
    const payload = {
      startDate: startDate,
      endDate: endDate
    };
    hitOnBackend(sendPromotionDataApi, payload);
    setDivState('block');
  }

  const handleResponse = (e) => {
    if (e === 'Data Not Found') {
      toast.error("Network Error");
    } else {
      setData(e);
    }
    setDivState('none');
  }

  let Loader = require('react-loader');

  return (
    <>
      <div className="loading-div" style={{ display: `${divState}` }}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>
      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="secondTab" className="tabcontent">
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Promotion</span>
          </div>
        </div>
        <div className="date-form">
          <span className="main-date-form">
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Revenue</span>
              </p>
            </div>
            <div className="date-inner">
              <div className="end-date">
                <label htmlFor="start">Start Date:</label>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  onChange={handleStartDateChange}
                />
              </div>
              <div className="end-date">
                <label htmlFor="start">End Date:</label>
                <input
                  type="date"
                  id="end"
                  name="trip-start"
                  onChange={handleEndDateChange}
                />
              </div>
              <div className="date-search-btn">
                <button className="bg-orange" type="submit" onClick={handleSearchButton}>
                  Search
                </button>
              </div>
            </div>
            <div className="main-box">
              <div className="table-sec">
                <table className="main-table">
                  <thead>
                    <tr>
                      <th>Partner</th>
                      <th>ServiceName</th>
                      <th>Total</th>
                      <th>Queue</th>
                      <th>Send</th>
                      <th>Skip</th>
                      <th>Duplicate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((row, index) => (
                        <tr key={index}>
                          <td>{row[0]}</td>
                          <td>{row[1]}</td>
                          <td>{row[2]}</td>
                          <td>{row[3]}</td>
                          <td>{row[4]}</td>
                          <td>{row[5]}</td>
                          <td>{row[6]}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">
                          <Message message="No Data Found!" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export default Promotiondata;
